@font-face {
  font-family: AvenirLight;
  src: url(./assets/fonts/AvenirLight.ttf) format("truetype");
}

@font-face {
  font-family: AvenirRegular;
  src: url(./assets/fonts/AvenirRoman.ttf) format("truetype");
}

@font-face {
  font-family: AvenirMedium;
  src: url(./assets/fonts/AvenirMedium.ttf) format("truetype");
}

@font-face {
  font-family: AvenirHeavy;
  src: url(./assets/fonts/AvenirBlack.ttf) format("truetype");
}

@import '~antd/dist/antd.css';

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: AvenirRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  color: #5F5F5F;
}

.flex-align-start {
  align-items: start;
}

div.flex-align-center {
  align-items: center;
}

div.flex-align-stretch {
  align-items: stretch;
}

div.flex-justify-center {
  justify-content: center;
}

.mb-0 {
  margin-bottom: 0;
}


.card {
  min-width: 420px;
  background-color: #fff;
  border: 1px solid rgba(0, 109, 255, 0.5);
  min-height: 600px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 420px;
}

.card__header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 109, 255, 0.5);
}

.card__header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.card__header__inner__left {
  flex: 1 1;
}

.card__header__inner__right {
  display: flex;
  flex: 1 1 0px;
  justify-content: flex-end;
}

.card__body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card__footer {
  text-align: center;
  padding: 20px;
}

.card__title {
  color: #5F5F5F;
  font-size: 40px;
  margin-bottom: 0;
  flex: 1 1;
  line-height: 1.2;
}

.orange.card {
  border: 1px solid rgba(246, 96, 54, 0.3);
}

.tag {
  font-size: 1em;
  font-family: AvenirHeavy;
  border-radius: 10px;
  padding: 5px 10px 2px;
  background-color: #f2f2f2;
  color: #5f5f5f;
}

.input__field {
  border: 1px solid rgba(0, 109, 255, 0.5);
  border-radius: 5px;
  height: 56px;
  line-height: 60px;
}

.input__field input {
  text-align: right;
  font-size: 25px;
  padding-right: 30px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  display: none;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.index_tokens img {
  width: 176px;
  height: 176px;
}

.font_25x {
  font-size: 25px;
}