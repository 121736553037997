.last-container {
  width: 100%;
  height: 700px;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 700px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  scale: 1;
  right: 0;
  overflow-x: hidden;
}

/* .section-helper-text {
    font-family: AvenirMedium, serif;
    font-size: 20px;
    color: white;
    padding: 10px 20px 25px;
} */

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
  margin-left: -18px;
}

.getStarted:hover {
  /* box-shadow: #ffffff85 0 5px 7px 0; */
  background-color: #0ea55a;
  /* transform: scale(1.05); */
}

.getStarted {
  width: 316px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #11be6a;
  padding: 15px;
}

.currencies {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.currencies-content {
  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 30s linear infinite;
}

@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.graphic-image {
  height: 60px;
  width: 60px;
  margin-bottom: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}
