.home-container {
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100vw 900px;
  flex-direction: column;
}

.center-Image {
  height: 136px;
  margin-top: -130px;
}

.section-heading {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 25px;
  text-align: center;
  margin-top: -40px;
}

.section-helper-text {
  font-family: AvenirMedium, serif;
  font-size: 15px;
  color: #d9d9d9;
  padding: 10px 20px 25px;
  text-align: center;
  margin-top: -30px;
}

.section-usp {
  color: white;
  font-family: AvenirMedium, serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 20px;

  padding-top: 120px;
  padding-bottom: 70px;
}

.font-big {
  color: white;
  font-size: 45px;
}

.big_trade_font {
  font-size: 120px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 20px;
}

.cut_button {
  width: 200px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  cursor: pointer;
  text-align: center;
}

.login-button {
  border-bottom: 50px solid #11be6a;
  border-left: 25px solid transparent;
  margin-right: -20px;
  margin-bottom: -42px;
}

.login-button:hover {
  border-bottom-color: #0ea55a;
  border-left-color: transparent;
}

.launch-app-button {
  border-top: 50px solid #006dff;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}
.launch-app-button:hover {
  border-top: 50px solid #00387e;
  border-right: 25px solid transparent;
  margin-bottom: -42px;
  margin-right: -20px;
}

.launch-app-button-text {
  margin-bottom: 48px;
}

.login-button_text {
  margin-top: 48px;
}

.td-none {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .section-heading {
    color: white;
    font-family: AvenirMedium, serif;
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
  }
}
